import { _x } from '@wordpress/i18n';
const BikeComparatorEmptyItem = () => {
  const link = window.theme.bikesUrl;
  return (
    <div className="bike-comparator-item bike-comparator-item--empty">
      <div className="bike-comparator-item__content-wrapper">
        <i className="icon icon-bike"></i>

        <div className="item__informations bike-comparator-item__informations">
          <h4 className="item__informations__title">
            {_x('Add a bike', 'Bike comparator item', 'vtx')}
          </h4>
        </div>

        <a href={link} className="btn bike-comparator-btn">
          {_x('Add a bike', 'Bike comparator item', 'vtx')}
        </a>
      </div>
      <a href={link} style={{ display: 'block', margin: '0 0 0 auto' }}>
        <i
          className="icon icon-add"
          title={_x('Add bike to comparator', 'Bike comparator item', 'vtx')}
        ></i>
      </a>
    </div>
  );
};

export default BikeComparatorEmptyItem;
