import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

domReady(() => {
  const alertsBar = document.querySelector('#alerts-bar');
  const alertsBarItems = document.querySelectorAll(
    '.alertsBarCarousel .swiper-slide'
  );
  const alertsCloseBtn = document.querySelectorAll('.alertsBarCloseBtn');
  let alertSwiper = null;

  if (alertsBar) {
    if (1 < alertsBarItems.length) {
      alertSwiper = new Swiper('.alerts-bar__carousel', {
        slidesPerView: 1,
        spaceBetween: 100,
        autoplay: {
          delay: 5000,
          disableOnInteraction: true, // stop the autoplay if user clicks on the pagination
          stopOnLastSlide: false, // stop on last slide, change to true if asked to end
          pauseOnMouseEnter: true, // stop the autoplay if user clicks anywhere in the slide
        },
        pagination: {
          el: '.swiper-pagination.alerts-swiper-pagination',
          clickable: true,
        },
      });

      let slideNumber = alertSwiper.realIndex + 1;
      const slideLenght = alertSwiper.slides.length;
      const paginationContainer = document.querySelector(
        '.swiperAlertsPaginationFraction'
      );
      paginationContainer.innerHTML = `${slideNumber} / ${slideLenght}`;

      alertSwiper.on('slideChange', () => {
        slideNumber = alertSwiper.realIndex + 1;
        paginationContainer.innerHTML = `${slideNumber} / ${slideLenght}`;
      });
    }

    if (0 < alertsCloseBtn.length) {
      alertsCloseBtn.forEach(function (closeBtn) {
        closeBtn.addEventListener('click', function () {
          alertSwiper.destroy();
          alertsBar.classList.remove('visible');
          document.cookie = 'remove_alerts_bar=true';
        });
      });
    }
  }
});
