import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

domReady(() => {
  const frontPageBannerCarouselContainer = document.querySelector(
    '.frontPageBannerCarousel'
  );
  const pageBannerSlides = document.querySelectorAll('.pageBannerSlide');

  if (frontPageBannerCarouselContainer) {
    if (1 < pageBannerSlides.length) {
      const frontPageBannerCarousel = new Swiper(
        frontPageBannerCarouselContainer,
        {
          slidesPerView: 1,
          autoplay: {
            delay: 5000,
            disableOnInteraction: true, // stop the autoplay if user clicks on the pagination
            stopOnLastSlide: false, // stop on last slide, change to true if asked to end
            pauseOnMouseEnter: true, // stop the autoplay if user clicks anywhere in the slide
          },

          pagination: {
            el: '.header-swiper-pagination',
            clickable: true,
          },
        }
      );

      let slideNumber = frontPageBannerCarousel.realIndex + 1;
      const slideLenght = frontPageBannerCarousel.slides.length;
      const paginationContainer = document.querySelector(
        '.swiperPaginationFraction'
      );
      paginationContainer.innerHTML = `${slideNumber} / ${slideLenght}`;

      frontPageBannerCarousel.on('slideChange', () => {
        slideNumber = frontPageBannerCarousel.realIndex + 1;
        paginationContainer.innerHTML = `${slideNumber} / ${slideLenght}`;
      });
    }
  }
});
