/* *
 * ========================================================================
 * Vortex Solution
 * ========================================================================
 * */

// Includes the custom Modernizr build based on the configurations set in .modernizrrc
require('Modernizr'); // eslint-disable-line import/no-extraneous-dependencies
require('nodelist-foreach-polyfill');
require('element-qsa-scope');
require('./main/dropdown-menu');
require('./main/mobile-menu');
require('./main/user-is-tabbing');
require('./main/forms');
require('./main/search-modal');
require('./main/breadcrumbs');
require('./main/popular-searches');
require('./main/front-page-slider');
require('./main/alerts-bar');
require('./main/facetwp');
require('./layout/index');
require('./layout/bike-comparator/headband/listing');
require('./layout/bike-comparator/headband/item');
require('./layout/bike-comparator/headband/empty-item');
require('./layout/bike-comparator/template/listing');
require('./layout/bike-comparator/template/item');
require('./layout/bike-comparator/template/empty-item');

import domReady from '@wordpress/dom-ready';

domReady(() => {});
