import PropTypes from 'prop-types';
import { _x } from '@wordpress/i18n';
import BikeComparatorCaracteristicsItem from './item';

const BikeComparatorCaracteristicsListing = ({
  caracteristics,
  sizes,
  colors,
}) => {
  const arrayToString = (array) => {
    let text = '';

    array.forEach((element, i) => {
      if (element) {
        if (typeof element === 'object') {
          element = element.name;
        }

        text += element;
        if (i !== array.length - 1) {
          text += ', ';
        }
      }
    });
    return text;
  };

  return (
    <>
      <BikeComparatorCaracteristicsItem
        label={_x('Frame', 'Bike comparator item', 'vtx')}
        value={caracteristics.frame}
      />
      <BikeComparatorCaracteristicsItem
        label={_x('Fork', 'Bike comparator item', 'vtx')}
        value={caracteristics.fork}
      />
      <BikeComparatorCaracteristicsItem
        label={_x('Weight (lb)', 'Bike comparator item', 'vtx')}
        value={caracteristics.weight}
      />
      <BikeComparatorCaracteristicsItem
        label={_x('Sizes', 'Bike comparator item', 'vtx')}
        value={arrayToString(sizes)}
      />
      <BikeComparatorCaracteristicsItem
        label={_x('Color(s)', 'Bike comparator item', 'vtx')}
        value={arrayToString(colors)}
      />
      <BikeComparatorCaracteristicsItem
        label={_x('Shifters', 'Bike comparator item', 'vtx')}
        value={caracteristics.shifters}
      />
      <BikeComparatorCaracteristicsItem
        label={_x('Front derailleur', 'Bike comparator item', 'vtx')}
        value={caracteristics.front_derailleur}
      />
      <BikeComparatorCaracteristicsItem
        label={_x('Rear derailleur', 'Bike comparator item', 'vtx')}
        value={caracteristics.rear_derailleur}
      />
      <BikeComparatorCaracteristicsItem
        label={_x('Brakes', 'Bike comparator item', 'vtx')}
        value={caracteristics.brakes}
      />
      <BikeComparatorCaracteristicsItem
        label={_x('Brakelevers', 'Bike comparator item', 'vtx')}
        value={caracteristics.brakelevers}
      />
      <BikeComparatorCaracteristicsItem
        label={_x('Crank', 'Bike comparator item', 'vtx')}
        value={caracteristics.crank}
      />
      <BikeComparatorCaracteristicsItem
        label={_x('Fork', 'Bike comparator item', 'vtx')}
        value={caracteristics.frame}
      />
      <BikeComparatorCaracteristicsItem
        label={_x('Chainrings', 'Bike comparator item', 'vtx')}
        value={caracteristics.chainrings}
      />
      <BikeComparatorCaracteristicsItem
        label={_x('Bottom bracket', 'Bike comparator item', 'vtx')}
        value={caracteristics.bottom_bracket}
      />

      <BikeComparatorCaracteristicsItem
        label={_x('Cassette', 'Bike comparator item', 'vtx')}
        value={caracteristics.cassette_cogs}
      />
      <BikeComparatorCaracteristicsItem
        label={_x('Chain', 'Bike comparator item', 'vtx')}
        value={caracteristics.chain}
      />
      <BikeComparatorCaracteristicsItem
        label={_x('Headset', 'Bike comparator item', 'vtx')}
        value={caracteristics.headset}
      />
      <BikeComparatorCaracteristicsItem
        label={_x('Handlebar', 'Bike comparator item', 'vtx')}
        value={caracteristics.handlebar}
      />
      <BikeComparatorCaracteristicsItem
        label={_x('Stem', 'Bike comparator item', 'vtx')}
        value={caracteristics.stem}
      />
      <BikeComparatorCaracteristicsItem
        label={_x('Grips tape', 'Bike comparator item', 'vtx')}
        value={caracteristics.grips_tape}
      />
      <BikeComparatorCaracteristicsItem
        label={_x('Handlebar', 'Bike comparator item', 'vtx')}
        value={caracteristics.handlebar}
      />
      <BikeComparatorCaracteristicsItem
        label={_x('Saddle', 'Bike comparator item', 'vtx')}
        value={caracteristics.saddle}
      />
      <BikeComparatorCaracteristicsItem
        label={_x('Seatpost', 'Bike comparator item', 'vtx')}
        value={caracteristics.seatpost}
      />
      <BikeComparatorCaracteristicsItem
        label={_x('Wheels rims', 'Bike comparator item', 'vtx')}
        value={caracteristics.wheels_rims}
      />
      <BikeComparatorCaracteristicsItem
        label={_x('Hubs', 'Bike comparator item', 'vtx')}
        value={caracteristics.hubs}
      />
      <BikeComparatorCaracteristicsItem
        label={_x('Tires', 'Bike comparator item', 'vtx')}
        value={caracteristics.tires}
      />
      <BikeComparatorCaracteristicsItem
        label={_x('Pedals', 'Bike comparator item', 'vtx')}
        value={caracteristics.pedals}
      />
      <BikeComparatorCaracteristicsItem
        label={_x('Other', 'Bike comparator item', 'vtx')}
        value={caracteristics.other_1}
      />
      <BikeComparatorCaracteristicsItem
        label={_x('Other', 'Bike comparator item', 'vtx')}
        value={caracteristics.other_2}
      />
      <BikeComparatorCaracteristicsItem
        label={_x('Other', 'Bike comparator item', 'vtx')}
        value={caracteristics.other_3}
      />
    </>
  );
};

BikeComparatorCaracteristicsListing.propTypes = {
  caracteristics: PropTypes.object,
  sizes: PropTypes.array,
  colors: PropTypes.array,
};

export default BikeComparatorCaracteristicsListing;
