export const SET_STOCKED_IDS = 'SET_STOCKED_IDS';

export const setStockedIds = (idsList) => ({
  type: SET_STOCKED_IDS,
  idsList,
});

export const SET_LOADING_STATUS = 'SET_LOADING_STATUS';

export const setLoadingStatus = (isLoading) => ({
  type: SET_LOADING_STATUS,
  isLoading,
});

export const SET_BIKE_LIST = 'SET_BIKE_LIST';

export const setBikeList = (bike, index, idsLength) => ({
  type: SET_BIKE_LIST,
  bike,
  index,
  idsLength,
});

export const SET_ALL_BIKE = 'SET_ALL_BIKE';

export const setAllBike = (bikeList) => ({
  type: SET_ALL_BIKE,
  bikeList,
});
