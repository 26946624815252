import {
  SET_ALL_BIKE,
  SET_BIKE_LIST,
  SET_LOADING_STATUS,
  SET_STOCKED_IDS,
} from '../actions/general';

const initialState = {
  isLoading: true,
  bikeList: [],
  stockedIds: localStorage.getItem('bike-comparator'),
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_LOADING_STATUS: {
      return {
        ...state,
        isLoading: action.isLoading,
      };
    }
    case SET_BIKE_LIST: {
      return state;
    }
    case SET_STOCKED_IDS: {
      return {
        ...state,
        stockedIds: action.idsList,
      };
    }
    case SET_ALL_BIKE: {
      const bikeList = !action.bikeList ? [] : action.bikeList;

      localStorage.setItem('bike-post-list', JSON.stringify(action.bikeList));

      return {
        ...state,
        bikeList,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default reducer;
