import { useEffect } from 'react';
import BikeComparatorHeadbandItem from './item';
import BikeComparatorCaracteristicsListing from './bike-details/caracteristics/listing';
import { _x } from '@wordpress/i18n';
import { accordionReactToggle } from '../../helpers';
import { useDispatch, useSelector } from 'react-redux';
import { getBikeList } from '../../actions/api';
import {
  setAllBike,
  setLoadingStatus,
  setStockedIds,
} from '../../actions/general';

const BikeComparatorListing = () => {
  const dispatch = useDispatch();

  const { isLoading, bikeList, stockedIds } = useSelector(
    (state) => state.comparator
  );

  const idsList = JSON.parse(stockedIds);

  const removeBike = (e, id) => {
    dispatch(setLoadingStatus(true));
    e.preventDefault();
    const idToRemove = idsList.indexOf(String(id));

    if (idToRemove > -1) {
      idsList.splice(idToRemove, 1);
      localStorage.setItem('bike-comparator', JSON.stringify(idsList));
      dispatch(setStockedIds(localStorage.getItem('bike-comparator')));

      const bikeToRemoveIndex = bikeList.findIndex((item) => item.ID === id);
      bikeList.splice(bikeToRemoveIndex, 1);

      localStorage.setItem('bike-post-list', JSON.stringify(bikeList));
      dispatch(setAllBike(bikeList));
    }
    dispatch(setLoadingStatus(false));
  };

  useEffect(() => {
    if (idsList) {
      const localBike = localStorage.getItem('bike-post-list');

      if (localBike && JSON.parse(localBike).length === idsList.length) {
        dispatch(setAllBike(JSON.parse(localBike)));
      } else {
        dispatch(getBikeList(idsList));
      }
    } else {
      dispatch(setLoadingStatus(false));
    }
  }, [stockedIds]);

  if (isLoading) {
    return <p>... {_x('Is loading', 'Bike comparator item', 'vtx')}</p>;
  }

  return (
    <div className="bike-comparateur-page-content">
      <section className="bike-comparator-items">
        <BikeComparatorHeadbandItem
          key="1"
          bike={bikeList[0]}
          removeBike={removeBike}
        />
        <BikeComparatorHeadbandItem
          key="2"
          bike={bikeList[1]}
          removeBike={removeBike}
        />
        <BikeComparatorHeadbandItem
          key="3"
          bike={bikeList[2]}
          removeBike={removeBike}
        />
      </section>

      <div className="block-accordions-group bike-comparator-accordions">
        <section
          onClick={accordionReactToggle}
          onKeyDown={accordionReactToggle}
          role="button"
          tabIndex={0}
          className="accordion accordions--item--technologies"
        >
          <div className="accordion__table">
            <div className="accordion__accordion">
              <button
                className="accordion__header-row accordion-toggle"
                id="accordionTechnologies"
                aria-expanded="false"
                aria-controls="sectionTechnologies"
              >
                <span className="accordion__header__title">
                  <h5>{_x('Technologies', 'Bike comparator item', 'vtx')}</h5>
                </span>
                <span></span>
                <span className="accordion__header__icon">
                  <i className="icon icon-caret-down"></i>
                </span>
              </button>

              <div
                className="accordion__sub-rows wysiwyg"
                id="sectionTechnologies"
                aria-labelledby="accordionTechnologies"
              >
                <div className="accordion__col technologies-list-item1">
                  {bikeList[0] &&
                    bikeList[0].bike_technology &&
                    bikeList[0].bike_technology.map((technology) => {
                      return (
                        <div
                          className="bike-item__technology"
                          key={technology.name}
                        >
                          <img
                            src={technology.techno_image}
                            alt={technology.name}
                          />
                        </div>
                      );
                    })}
                </div>
                <div className="accordion__col technologies-list-item2">
                  {bikeList[1] &&
                    bikeList[1].bike_technology &&
                    bikeList[1].bike_technology.map((technology) => {
                      return (
                        <div
                          className="bike-item__technology"
                          key={technology.name}
                        >
                          <img
                            src={technology.techno_image}
                            alt={technology.name}
                          />
                        </div>
                      );
                    })}
                </div>
                <div className="accordion__col technologies-list-item3">
                  {bikeList[2] &&
                    bikeList[2].bike_technology &&
                    bikeList[2].bike_technology.map((technology) => {
                      return (
                        <div
                          className="bike-item__technology"
                          key={technology.name}
                        >
                          <img
                            src={technology.techno_image}
                            alt={technology.name}
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          onClick={accordionReactToggle}
          onKeyDown={accordionReactToggle}
          tabIndex={0}
          role="button"
          className="accordion accordions--item--caracteristics"
        >
          <div className="accordion__table">
            <div className="accordion__accordion">
              <button
                className="accordion__header-row accordion-toggle"
                id="accordionCaracteristics"
                aria-expanded="false"
                aria-controls="sectionCaracteristics"
              >
                <span className="accordion__header__title">
                  <h5>
                    {_x('Characteristics', 'Bike comparator item', 'vtx')}
                  </h5>
                </span>
                <span></span>
                <span className="accordion__header__icon">
                  <i className="icon icon-caret-down"></i>
                </span>
              </button>

              <div
                className="accordion__sub-rows wysiwyg"
                id="sectionCaracteristics"
                aria-labelledby="accordionCaracteristics"
              >
                <div className="accordion__col caracteristics-list-item1">
                  {bikeList[0] && (
                    <BikeComparatorCaracteristicsListing
                      key="1"
                      caracteristics={bikeList[0].bike_caracteristics}
                      sizes={bikeList[0].bike_size}
                      colors={bikeList[0].bike_color}
                    />
                  )}
                </div>

                <div className="accordion__col caracteristics-list-item2">
                  {bikeList[1] && (
                    <BikeComparatorCaracteristicsListing
                      key="2"
                      caracteristics={bikeList[1].bike_caracteristics}
                      sizes={bikeList[1].bike_size}
                      colors={bikeList[1].bike_color}
                    />
                  )}
                </div>

                <div className="accordion__col caracteristics-list-item3">
                  {bikeList[2] && (
                    <BikeComparatorCaracteristicsListing
                      key="3"
                      caracteristics={bikeList[2].bike_caracteristics}
                      sizes={bikeList[2].bike_size}
                      colors={bikeList[2].bike_color}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          onClick={accordionReactToggle}
          onKeyDown={accordionReactToggle}
          tabIndex={0}
          role="button"
          className="accordion accordions--item--geometry"
        >
          <div className="accordion__table">
            <div className="accordion__accordion">
              <button
                className="accordion__header-row accordion-toggle"
                id="accordionGeometry"
                aria-expanded="false"
                aria-controls="sectionGeometry"
              >
                <span className="accordion__header__title">
                  <h5>{_x('Geometry', 'Bike comparator item', 'vtx')}</h5>
                </span>
                <span></span>
                <span className="accordion__header__icon">
                  <i className="icon icon-caret-down"></i>
                </span>
              </button>

              <div
                className="accordion__sub-rows wysiwyg"
                id="sectionGeometry"
                aria-labelledby="accordionGeometry"
              >
                <div className="bike-geometry__item">
                  {bikeList[0] && bikeList[0].geo_table_image && (
                    <img src={bikeList[0].geo_table_image} alt="" />
                  )}
                  {bikeList[0] && bikeList[0].geo_table && (
                    <div className="bike-geometry__item--table">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: bikeList[0].geo_table,
                        }}
                      />
                    </div>
                  )}
                </div>

                <div className="bike-geometry__item">
                  {bikeList[1] && bikeList[1].geo_table_image && (
                    <img src={bikeList[1].geo_table_image} alt="" />
                  )}
                  {bikeList[1] && bikeList[1].geo_table && (
                    <div className="bike-geometry__item--table">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: bikeList[1].geo_table,
                        }}
                      />
                    </div>
                  )}
                </div>

                <div className="bike-geometry__item">
                  {bikeList[2] && bikeList[2].geo_table_image && (
                    <img src={bikeList[2].geo_table_image} alt="" />
                  )}
                  {bikeList[2] && bikeList[2].geo_table && (
                    <div className="bike-geometry__item--table">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: bikeList[2].geo_table,
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default BikeComparatorListing;
