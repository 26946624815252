import axios from 'axios';
import { GET_BIKE_LIST } from '../actions/api';
import { setAllBike } from '../actions/general';

const apiMiddleware = (store) => (next) => (action) => {
  const baseUrl = window.location.origin;
  const lang = window.theme.language;

  const api = axios.create({
    baseURL: baseUrl,
  });

  switch (action.type) {
    case GET_BIKE_LIST: {
      const idsList = action.ids.join('-');
      api
        .get('/wp-json/api/v1/get-bikes-list/' + idsList + '?lang=' + lang)
        .then(async (response) => {
          if (!response.data) {
            return {};
          }

          const bikeData = response.data;

          store.dispatch(setAllBike(bikeData.post_list));
        })
        .catch((error) => {
          console.log(error);
        });

      next(action);
      break;
    }
    default:
      next(action);
      break;
  }
};

export default apiMiddleware;
