import PropTypes from 'prop-types';

const BikeComparatorCaracteristicsItem = ({ label, value }) => (
  <div className="bike-caracteristic__item">
    <strong className="bike-caracteristic__label">{label}</strong>
    <p className="bike-caracteristic__value">{value}</p>
  </div>
);

BikeComparatorCaracteristicsItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export default BikeComparatorCaracteristicsItem;
