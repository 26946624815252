import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BikeComparatorHeadbandItem from './item';
import { getBikeList } from '../../actions/api';
import {
  setAllBike,
  setLoadingStatus,
  setStockedIds,
} from '../../actions/general';

const BikeComparatorHeadbandListing = () => {
  const dispatch = useDispatch();

  const { isLoading, bikeList, stockedIds } = useSelector(
    (state) => state.comparator
  );

  const idsList = JSON.parse(stockedIds);

  const removeBike = (e, id) => {
    dispatch(setLoadingStatus(true));
    e.preventDefault();
    const idToRemove = idsList.indexOf(String(id));

    const bikeRemovedEvent = new CustomEvent('bike-comparator-removed', {
      detail: {
        postId: id,
      },
    });

    if (idToRemove > -1) {
      idsList.splice(idToRemove, 1);
      localStorage.setItem('bike-comparator', JSON.stringify(idsList));
      dispatch(setStockedIds(localStorage.getItem('bike-comparator')));

      const bikeToRemoveIndex = bikeList.findIndex((item) => item.ID === id);
      bikeList.splice(bikeToRemoveIndex, 1);

      localStorage.setItem('bike-post-list', JSON.stringify(bikeList));
      window.dispatchEvent(bikeRemovedEvent);
      dispatch(setAllBike(bikeList));
    }
    dispatch(setLoadingStatus(false));
  };

  const handleLocalStorage = () => {
    dispatch(setStockedIds(localStorage.getItem('bike-comparator')));
  };

  window.addEventListener(
    'bike-comparator-storage-updated',
    handleLocalStorage
  );

  useEffect(() => {
    if (idsList) {
      const localBike = localStorage.getItem('bike-post-list');

      if (localBike && JSON.parse(localBike).length === idsList.length) {
        dispatch(setAllBike(JSON.parse(localBike)));
      } else {
        dispatch(getBikeList(idsList));
      }
    } else {
      dispatch(setLoadingStatus(false));
    }
  }, [stockedIds]);

  if (isLoading) {
    return <p>... is loading</p>;
  }

  return (
    <section className="bike-comparator-items">
      <BikeComparatorHeadbandItem
        key="1"
        post={bikeList[0]}
        removeBike={removeBike}
      />
      <BikeComparatorHeadbandItem
        key="2"
        post={bikeList[1]}
        removeBike={removeBike}
      />
      <BikeComparatorHeadbandItem
        key="3"
        post={bikeList[2]}
        removeBike={removeBike}
      />
    </section>
  );
};

export default BikeComparatorHeadbandListing;
