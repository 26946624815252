export const accordionReactToggle = (evt) => {
  if ('click' === evt.type || 'Tab' === evt.code) {
    const accordionToggle = evt.target.closest('button');

    accordionToggle.closest('.accordion__accordion').classList.toggle('open');
    if (
      accordionToggle
        .closest('.accordion__accordion')
        .classList.contains('open')
    ) {
      accordionToggle.setAttribute('aria-expanded', true);
    } else {
      accordionToggle.setAttribute('aria-expanded', false);
    }
  }
};
