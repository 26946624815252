import PropTypes from 'prop-types';
import { _x } from '@wordpress/i18n';
import BikeComparatorHeadbandEmptyItem from './empty-item';

const BikeComparatorHeadbandItem = ({ post, removeBike }) => {
  if (!post) {
    return <BikeComparatorHeadbandEmptyItem />;
  }

  return (
    <div className="bike-comparator-item">
      <div className="bike-comparator-item__content-wrapper">
        <img
          className="bike-comparator-item__image"
          src={post.image}
          alt={post.model}
        />
        <a
          href={post.link}
          className="item__informations bike-comparator-item__informations"
        >
          <h4 className="item__informations__title">{post.model}</h4>
          <p className="item__informations__text">{post.promottedAttribute}</p>
        </a>
      </div>
      <button
        className="item__close-btn"
        onClick={(e) => removeBike(e, post.ID)}
      >
        <i
          className="icon icon-close"
          title={_x('Remove bike', 'Bike comparator item', 'vtx')}
        ></i>
      </button>
    </div>
  );
};

BikeComparatorHeadbandItem.propTypes = {
  post: PropTypes.object,
  removeBike: PropTypes.func.isRequired,
};

export default BikeComparatorHeadbandItem;
