import { _x } from '@wordpress/i18n';
import BikeComparatorHeadbandEmptyItem from './empty-item';

const BikeComparatorItem = ({ bike, removeBike }) => {
  if (!bike) {
    return <BikeComparatorHeadbandEmptyItem />;
  }

  return (
    <div className="bike-comparator-item">
      <div className="bike-comparator-item__content-wrapper">
        <img
          className="bike-comparator-item__image"
          src={bike.image}
          alt={bike.name}
        />
        <a
          href={bike.link}
          className="item__informations bike-comparator-item__informations"
        >
          <h4 className="item__informations__title">{bike.model}</h4>
          <p className="item__informations__text">{bike.bike_subtitle}</p>
          {(() => {
            if (bike.bike_status === 'Current') {
              return (
                <p className="item__informations__price">
                  {bike.price} $
                  <span>{_x('CAD$', 'Bike comparator item', 'vtx')}</span>
                </p>
              );
            }
            if (bike.bike_status === 'Archive') {
              return (
                <p className="item__informations__status">{bike.bike_status}</p>
              );
            }
          })()}

          <div className="item__informations__colors">
            <p>{_x('Colors', 'Bike comparator item', 'vtx')}:</p>
            <div className="item__informations__colors-list">
              {bike.bike_color.map((item) => {
                return (
                  <div className="bike-item__color_variation" key={item.name}>
                    <img
                      src={item.bike_vignette_image}
                      title={item.name}
                      alt={item.name}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="item__informations__sizes">
            <p>{_x('Sizes', 'Bike comparator item', 'vtx')}:</p>
            <div className="item__informations__sizes-list">
              {bike.bike_size.map((sizevariation) => {
                return (
                  <div
                    className="bike-item__size_variation"
                    key={sizevariation.name}
                  >
                    {sizevariation.name}
                  </div>
                );
              })}
            </div>
          </div>
        </a>
        <a href={bike.link} className="btn bike-comparator-btn">
          {_x('See the detail of the bike', 'Bike comparator item', 'vtx')}
        </a>
      </div>
      <button
        className="item__close-btn"
        onClick={(e) => removeBike(e, bike.ID)}
      >
        <i
          className="icon icon-close"
          title={_x('Remove bike', 'Bike comparator item', 'vtx')}
        ></i>
      </button>
    </div>
  );
};

export default BikeComparatorItem;
