import { combineReducers } from 'redux';

// import reducers
import comparatorReducer from './comparator';

const rootReducer = combineReducers({
  comparator: comparatorReducer,
});

export default rootReducer;
