import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import BikeComparatorHeadbandListing from './bike-comparator/headband/listing';
import BikeComparatorListing from './bike-comparator/template/listing';
import store from './store';

const headbandListingNode = document.getElementById(
  'bikeComparatorHeadbandListing'
);
if (headbandListingNode) {
  const headbandListingRoot = createRoot(headbandListingNode);
  headbandListingRoot.render(
    <Provider store={store}>
      <BikeComparatorHeadbandListing />
    </Provider>
  );
}

const listingNode = document.getElementById('bikeComparatorTemplate');
if (listingNode) {
  const listingRoot = createRoot(listingNode);
  listingRoot.render(
    <Provider store={store}>
      <BikeComparatorListing />
    </Provider>
  );
}
